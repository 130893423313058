<template>
  <div class="system_view">
    <el-menu :default-active="defaultActive" class="el-menu-demo" mode="horizontal" router>
      <el-menu-item index="basicSys">基础配置</el-menu-item>
      <el-menu-item index="liveSys">直播配置</el-menu-item>
      <!-- <el-menu-item index="vodSys">点播配置</el-menu-item> -->
      <el-menu-item index="smsSys">短信配置</el-menu-item>
       <el-menu-item index="smsTemplate">阿里短信模板</el-menu-item>
      <el-menu-item index="paySys">支付配置</el-menu-item>
      <el-menu-item index="wxSys">微信配置</el-menu-item>
      <el-menu-item index="txColudSys">腾讯云对象存储</el-menu-item>
    </el-menu>
    <router-view></router-view>


  </div>
</template>
<script>
export default {
  data() {
    return {
      defaultActive:'vodSys'
    };
  },
  created(){
    //获取当前路由地址
    const routerPath=this.$route.path;
    //定位菜单为当前路由地址
    this.defaultActive=routerPath.substring(1,routerPath.length);
  }
};
</script>
<style lang='scss'>

.card_content {
  margin-top: 15px;
  // background: #ffffff;
  // border-radius: 4px;
}
</style>